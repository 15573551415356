<template lang="pug">
.related-products-wrapper(v-if="relatedProducts.length > 0")
  h2.header Related Products
  .related-products
    .related-product(
      v-for="(product, i) in relatedProducts"
      @click="goToProduct(product)"
      :key="i"
    )
      vue-image.product-image(
        :source='product.images[0].url'
        :height='500'
        :width='500'
      )
      h4.title {{ product.title }}
      p.price {{ product.variants[0].price && product.variants[0].price.amount ? product.variants[0].price.amount : product.variants[0].price | currency }}
</template>

<script>
import { mapGetters } from 'vuex'
import { goToProduct } from '@/helpers/goToProduct'

export default {
  name: 'related-products',
  components: {
  },
  computed: {
    ...mapGetters('products', [
      'relatedProducts'
    ])
  },
  methods: {
    goToProduct
  }
}
</script>

<style lang="scss" scoped>
.related-products-wrapper {
  margin: 2em 0;

  .related-products {
    display: grid;
    grid-gap: 1.25em;
    grid-template-columns: 1fr;
    cursor: pointer;
    margin-top: 1em;

    @media screen and (min-width: 20em) {
      grid-template-columns: repeat(2, 1fr);
    }

    .related-product {
      cursor: pointer;
      transition: opacity 0.5s;

      &:hover {
        opacity: 0.8;
      }

      .product-image {
        margin-bottom: 0.5em;
      }

      .title, .price {
        margin: 0;
      }

      .title {
        margin-top: 0.25em;
      }
    }
  }

  .header {
    margin-bottom: 0.25em;
    text-align: center;
  }
}
</style>
