<template lang="pug">
  .max-width
    .product-wrapper(v-if="Object.keys(product).length > 0" :key="product.handle")
      .product
        .left
          vue-image.product-image(
            :source='product.images.length > 0 && product.images[0].src || product.images.length > 0 && product.images[0].url || ""'
            :width='500'
            :height='500'
          )
        .right
          h1.title {{ product.title }}
          .vendor-price
            h4.vendor {{ product.vendor }}
            .price
              p.individual-price(v-if="itemQuantity > 1 && foundVariant") {{ itemQuantity }} @ {{ foundVariant.price.amount || foundVariant.price | currency }}
              .prices
                h3(v-if="computedCompareAtPrice").original-price {{ computedCompareAtPrice | currency }}
                h3.price {{ computedPrice | currency }}
          .buy
            quantity-input(
              v-if="product.availableForSale"
              v-model.number='itemQuantity'
              v-bind:is-cart="false"
              :disabled="!product.availableForSale"
            )
            select(v-model="selectedVariant" v-if="computedVariants.length > 1" name="variant")
              option(value="" disabled) Select an Option
              option(v-for="option in computedVariants" :value="option.id") {{ option.title }}
            button.atc(
              v-if="product.availableForSale"
              @click="addToCart()"
              v-bind:class="{ 'loading': addingToCart }"
            ) Add To Cart
            button.atc.disabled(
              v-else
            ) Unavailable
      .description(v-html="product.descriptionHtml")
      .additional-images(v-if="additionalImages.length > 0")
        vue-image.product-image(
          v-for="image in additionalImages"
          :key="image.url || image.src"
          :source="image.url || image.src"
          :width='500'
          :height='500'
        )
      .features
        h4.features-header All my products come with:
        ol
          li.feature #[span 🙏&nbsp;] A Blessing By Me, An Ordained Minister
          li.feature #[span 💁‍♂️&nbsp;] 24/7 Customer Support, Also By Me
          li.feature #[span 💯&nbsp;] 100% Satisfaction Guarantee
      related-products(v-if="!prerender")
      .prev-next(v-if="previousProduct || nextProduct")
        .prev
          div(v-if="previousProduct" @click.prevent="goToProduct(previousProduct)")
            p Prev.
            a {{ previousProduct.title }}
        .next
          div(v-if="nextProduct" @click="goToProduct(nextProduct)")
            p Next
            a {{ nextProduct.title }}
    loading(page="product" :title="productTitle" v-else)
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { goToProduct, setMetaForProduct } from '@/helpers/goToProduct'
import RelatedProducts from '@/components/RelatedProducts.vue'

export default {
  name: 'Product',

  components: {
    RelatedProducts
  },

  data: () => ({
    selectedVariant: '',
    itemQuantity: 1
  }),

  computed: {
    ...mapState([
      'prerender'
    ]),

    ...mapState('cart', [
      'cart',
      'addingToCart'
    ]),

    ...mapState('products', [
      'product'
    ]),

    ...mapGetters('products', [
      'previousProduct',
      'nextProduct'
    ]),

    computedVariants () {
      return this.product.variants || []
    },

    foundVariant () {
      return this.computedVariants.find(variant => variant.id === this.selectedVariant)
    },

    computedCompareAtPrice () {
      return this.foundVariant ? (this.foundVariant.compareAtPrice?.amount || this.foundVariant.compareAtPrice) * this.itemQuantity : false
    },

    computedPrice () {
      return this.foundVariant ? (this.foundVariant.price?.amount || this.foundVariant.price) * this.itemQuantity : 0
    },

    additionalImages () {
      return [...this.product.images].slice(1)
    }
  },

  watch: {
    '$route': {
      immediate: true,
      async handler (to, from) {
        // set variant
        if (this.computedVariants.length > 0) {
          this.selectedVariant = this.computedVariants[0].id
        }

        // if direct route, fetch product data
        if (Object.keys(this.product).length <= 0) {
          this.productTitle = this.$route.path.replace(/\/product\//g, '').replace(/\//g, '')
          await this.$store.dispatch('products/fetchProductByHandle', this.productTitle)
          this.selectedVariant = this.computedVariants[0].id
        }

        // set item quantity to one
        this.itemQuantity = 1

        // set metadata
        this.setMetaForProduct(this.product)
      }
    }
  },

  methods: {
    goToProduct,
    setMetaForProduct,

    async addToCart () {
      await this.$store.dispatch('cart/addProduct', [{
        variantId: this.selectedVariant,
        quantity: this.itemQuantity
      }])

      this.$store.commit('toggleSideCart')
    }
  }
}
</script>

<style lang="scss">
// not scoped to share with loading
.product-wrapper .product,
.loading-wrapper .product {
  @media(min-width: $bp-s) {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 1.5em;
    margin-bottom: 1.5em;
  }
}

.left .product-image /deep/ img,
.left .product-image /deep/ svg {
  display: block;
}

.right {
  padding: 1em 0;
  text-align: left;

  @media(min-width: $bp-s) {
    padding: 0;
  }

  .title {
    margin: 0 0 0.5em 0;
  }

  .vendor-price {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1em;

    .vendor {
      margin: 0 1em 0 0;
      font-weight: normal;
    }

    .price {
      position: relative;
      text-align: right;

      .individual-price {
        font-size: 0.8em;
        position: absolute;
        margin-bottom: 0;
        top: -2.75em;
        right: 0;
      }

      .prices {
        display: flex;

        * {
          margin-bottom: 0;
        }

        .original-price {
          color: $black;
          position: relative;
          margin-right: 0.25em;

          &:after {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            right: 0;
            border-top: 2px solid;
            border-color: inherit;
          }
        }

        .original-price+.price {
          color: $red;
        }
      }
    }
  }

  .buy {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    /deep/ .quantity-input {
      margin: 0;
    }

    select,
    .atc,
    /deep/ .quantity-input {
      margin: 0.5rem 0;
    }

    .quantity-input+.atc {
      flex-grow: 1;
      margin-left: 1rem;
    }

    .quantity-input~select~.atc {
      margin-left: 0;
    }

    .atc {
      transition: none;
    }

    select,
    button {
      flex-basis: 50%;
      flex-grow: 1;
    }

    select {
      margin-left: 1em;
    }

    button {
      margin: 0;
    }
  }
}

.description {
  margin: 1em 0 1.5em;
}

.additional-images .product-image {
  margin-bottom: 1.5em;
}

.features {
  margin-top: 5em;
  margin-bottom: 5em;
  text-align: center;

  ol {
    list-style: none;
    margin-bottom: 0;

    li {
      margin-left: 0;
      font-style: italic;

      span {
        font-size: 2em;
        font-style: normal;
        vertical-align: middle;
        margin-right: 1rem;
      }
    }
  }

  .features-header {
    margin-bottom: 0;
  }
}

.prev-next {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1.5em 0 0;

  >div {
    flex-basis: 47%;

    &:last-of-type {
      text-align: right;
    }

    p {
      font-size: 0.8em;
      margin: 0;
    }

    a {
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
